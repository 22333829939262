import { i18n, i18next } from "@health/i18n";
import { Branch, BranchOrderField, VendorBranchTypeEnum } from "@health/queries";
import { CustomTableColumnProps, format, getTranslatedNameField } from "@health/ui";
import { IsActiveToggleButton } from "./ActiveToggle.component";
import { BranchAddressInfo } from "./BranchAddressInfo.component";
import { WorkingHoursBranch } from "./WorkingHoursBranch.component";
import { YusurCredentialsDialog } from "./YusurCredentials.component";

const types = Object.keys(VendorBranchTypeEnum).map(key => ({ value: VendorBranchTypeEnum[key], name: key }));
const typeValue = value => types?.find(item => item?.value === value);

export const getBranchesColumns = (t: i18next.TFunction): CustomTableColumnProps<Branch>[] => [
  {
    key: "name",
    header: t("Name"),
    accessor: row => getTranslatedNameField(row),
    isSortable: true,
    sortDirection: undefined,
    sortColumnEnum: BranchOrderField.Name,
  },
  {
    key: "created",
    header: t("Created"),
    accessor: row => format(row?.created, "DD MMM YYYY, h:mm", i18n.language),
    isSortable: true,
    sortDirection: "DESC",
    sortColumnEnum: BranchOrderField.Created,
  },
  {
    key: "description",
    header: t("Description"),
    accessor: "description",
  },
  {
    key: "credential",
    header: t("Wasfaty Credential"),
    accessor: row => <YusurCredentialsDialog row={row} />,
  },
  {
    key: "contactNumber",
    header: t("Contact Number"),
    accessor: "contactNumber",
  },
  {
    key: "healthLicense",
    header: t("Health License"),
    accessor: "healthLicense",
  },
  {
    key: "types",
    header: t("Type"),
    accessor: ({ type }) => t(typeValue(type)?.name!, { context: "en" }),
  },
  {
    key: "workingHours",
    header: t("Working Hours"),
    accessor: row => <WorkingHoursBranch workingHours={row?.workingHours} />,
  },
  {
    key: "address",
    header: t("Address"),
    accessor: row => {
      return <BranchAddressInfo address={row?.address} />;
    },
  },
  {
    key: "isActive",
    header: t("Is Active"),
    accessor: row => <IsActiveToggleButton id={row?.id} isActive={row?.isActive} />,
  },
];
